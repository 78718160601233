import DashboardMaster from '@/layout/dashboard/DashboardMaster'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/Card';
import Loading from '@/shared/common/Loading';
import React, { useState, useCallback, useMemo, useEffect, lazy, Suspense } from "react"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { Slider } from "@/components/ui/slider"
import { ChevronRight, Edit, Lock, Trash2, Unlock } from "lucide-react"
import TooltipProviders from '@/components/common/TooltipProvider';
import { cn } from '@/lib/utils';
import CustomCreatableSelect from '@/components/common/customCreatableSelect';
import { useDispatch } from 'react-redux';
import { fetchStrategyAll } from '@/store/actions/strategyAction';
import { AddAllocationV2, getAllocatedAuthorizedAssets, getAllocatedPortfolios, lockUnlockAssetAllocation, removeAssetAllocation } from '../../../../store/actions/allocationV2Action';
import { fetchAllocationsDetails } from '@/store/actions/AllocationAction';
import { fetchClass } from '@/store/actions/classAction';
import { fetchSubClass } from '@/store/actions/subClassAction';
import { IconLoading } from '../../../../shared/common/Loading';
// import { initialAllocation } from '@/static/static';
const TotalAllocationCheck = lazy(() => import("../_components/TotalAllocationCheck"));
const MainTabs = lazy(() => import("../_components/tabs/main-tabs"));

export function replaceAssetsWithAuthorizedObjects(data) {
    // Step 1: Create a map for quick lookup of authorized assets by ID
    const authorizedAssetMap = data?.authorizeAssets?.reduce((map, asset) => {
        map[asset?._id] = asset;
        return map;
    }, {});

    // Step 2: Recursive function to process each asset and replace the `asset` field
    function processAsset(item) {
        // Check if `asset` is non-null and exists in authorized assets
        if (item.asset && authorizedAssetMap[item?.asset]) {
            item.asset = authorizedAssetMap[item?.asset]; // Replace `asset` field with the authorized asset object
        }

        // If `allocatedSubAssets` exists, process each sub-asset recursively
        if (item.allocatedSubAssets && item?.allocatedSubAssets.length > 0) {
            item.allocatedSubAssets = item?.allocatedSubAssets.map(processAsset);
        }

        return item;
    }

    // Step 3: Apply the replacement process on each top-level allocated asset
    data.allocatedAssets = data?.allocatedAssets.map(processAsset);

    return data;
}
function makeNestedAssetAllocation(assetAllocations) {
    const allocationMap = {};
    const nestedAllocations = [];

    // Step 1: Create a map of allocations by _id and initialize allocatedSubAssets
    assetAllocations.forEach(allocation => {
        allocation.allocatedSubAssets = []; // Initialize allocatedSubAssets for every allocation
        allocationMap[allocation._id] = allocation; // Map allocation by its ID
    });

    // Step 2: Organize allocations into a nested structure
    assetAllocations.forEach(allocation => {
        if (allocation?.parentAssetAllocation) {
            // Find parent allocation using allocationMap
            const parentAllocation = allocationMap[allocation.parentAssetAllocation];
            if (parentAllocation) {
                parentAllocation.allocatedSubAssets.push(allocation); // Add to parent's allocatedSubAssets
            }
        } else {
            nestedAllocations.push(allocation); // Top-level allocations (no parentAssetAllocation)
        }
    });

    return nestedAllocations;
}


const DynamicAllocation = (props) => {
    const [targetType, setTargetType] = useState("Portfolio Group")
    const [selectedPortfolio, setSelectedPortfolio] = useState("Moderate Portfolio")
    const [selectedPortfolioGroup, setSelectedPortfolioGroup] = useState("Moderate Group")
    const [allocation, setAllocation] = useState([]);
    // const [authorizedAssets, setAuthorizedAssets] = useState([])
    const [expandedGroups, setExpandedGroups] = useState({})
    const [newAllocation, setNewAllocation] = useState({
        name: "",
        strategic: 0,
        tactical: 0,
        strategicToleranceLower: 3,
        strategicToleranceUpper: 5,
        tacticalToleranceLower: 1,
        tacticalToleranceUpper: 2,
        authorizedAsset: "",
        class: "",
        subClass: "",
        strategy: "",
        allocationType: {
            value: 'Fixed Weight',
            label: 'Fixed Weight',
        },
        totalToleranceLower: 5,
        totalToleranceUpper: 7,
        portfolio: null,
    })
    const [newSubAllocation, setNewSubAllocation] = useState({
        name: "",
        strategic: 0,
        tactical: 0,
        strategicToleranceLower: 2,
        strategicToleranceUpper: 3,
        tacticalToleranceLower: 1,
        tacticalToleranceUpper: 2,
        authorizedAsset: "",
        class: "",
        subClass: "",
        strategy: "",
        parent: "",
        allocationType: {
            value: 'Fixed Weight',
            label: 'Fixed Weight',
        },
        totalToleranceLower: 5,
        totalToleranceUpper: 7,
        portfolio: null,
    })
    const [savedAllocations, setSavedAllocations] = useState({})
    const [currentAllocationName, setCurrentAllocationName] = useState('')
    const [auditLog, setAuditLog] = useState([]);
    const [currentAllocation, setCurrentAllocation] = useState(null);
    const [allStrategies, setAllStrategies] = useState([]);
    const [allocationValidationErrors, setAllocationValidationErrors] = useState({});
    const [mainTabs, setMainTabs] = useState('view');
    const [lockedAllocations, setLockedAllocations] = useState({})
    const [editableAllocations, setEditableAllocations] = useState([]);
    const [UpdatedAllocation, setUpdatedAllocation] = useState([]);
    const [allClasses, setAllClasses] = useState([]);
    const [allSubClasses, setAllSubClasses] = useState([]);
    const [allAuthorizedAssets, setAllAuthorizedAssets] = useState([]);
    const [allPortfolios, setAllPortfolios] = useState([]);
    const [adjustSaveLoading, setAdjustSaveLoading] = useState(false);
    const dispatch = useDispatch();


    useMemo(() => {
        const currentAllocation = JSON.parse(localStorage.getItem('currentAllocation'));
        if (currentAllocation) {
            setCurrentAllocation(currentAllocation);
        }
    }, [localStorage.getItem('currentAllocation')]);

    const [currentAllocationDetails, setCurrentAllocationDetails] = useState([]);

    useEffect(() => {
        if (currentAllocationDetails?.length > 0) {
            const assetAllocationData = makeNestedAssetAllocation(currentAllocationDetails);
            const newData = assetAllocationData?.length > 0 ? assetAllocationData?.map((allocation) => {
                return {
                    ...allocation,
                    TLD: allocation?.TALT - (allocation?.SAA + allocation?.TAA),
                    TUD: allocation?.TAUT - (allocation?.SAA + allocation?.TAA),
                    SLD: allocation?.SAALT - allocation?.SAA,
                    SUD: allocation?.SAAUT - allocation?.SAA,
                }
            }) : [];
            setUpdatedAllocation(newData);
        }
    }, [currentAllocationDetails]);


    useEffect(() => {
        const allocationId = JSON.parse(localStorage.getItem("currentAllocation")) || null;

        dispatch(fetchClass()).then((response) => {
            if (response?.data?.success) {
                setAllClasses(response?.data?.data?.records)
            }
        })
        dispatch(fetchSubClass()).then((response) => {
            if (response?.data?.success) {
                setAllSubClasses(response?.data?.data?.records)
            }
        })

        dispatch(getAllocatedAuthorizedAssets(allocationId?.value))
            .then((res) => {
                if (res?.data?.success) {
                    setAllAuthorizedAssets(res?.data?.data);
                }
            });

        dispatch(getAllocatedPortfolios(allocationId?.value))
            .then((res) => {
                res?.data?.success && setAllPortfolios(res?.data?.data?.records);
            })
            .catch((error) => {
                console.log(error);
            });

        dispatch(fetchStrategyAll('all')).then((response) => {
            if (response?.data?.success) {
                setAllStrategies(response?.data?.data?.records);
            }
        })
    }, [])

    useEffect(() => {
        if (UpdatedAllocation?.length > 0) {
            localStorage.setItem('currentAllocationDetails', JSON.stringify(UpdatedAllocation));

            setAllocation(UpdatedAllocation);
        }
    }, [UpdatedAllocation]);

    useEffect(() => {
        if (allStrategies?.length > 0) {
            localStorage.setItem('allStrategies', JSON.stringify(allStrategies?.map((strategy) => {
                return {
                    value: strategy._id,
                    label: strategy.name,
                    strategy: { ...strategy }
                }
            })));
        } else {
            localStorage.setItem('allStrategies', JSON.stringify([]));
        }

    }, [allStrategies]);


    useEffect(() => {
        const editableRecords = [];

        const changeEditable = (assets) => {
            assets.forEach((asset) => {
                if (asset.isEditable) {
                    editableRecords.push(asset);
                }
                if (asset.allocatedSubAssets && asset.allocatedSubAssets.length > 0) {
                    changeEditable(asset.allocatedSubAssets);
                }
            });
        };

        changeEditable(allocation);

        setEditableAllocations(editableRecords);
    }, [allocation]);


    useEffect(() => {
        const initializeAllocationType = (assets) => {
            return assets.map((asset) => {
                if (!asset.allocationType) {
                    asset.allocationType = {
                        value: 'Fixed Weight',
                        label: 'Fixed Weight',
                    };
                }
                if (asset.allocatedSubAssets && asset.allocatedSubAssets.length > 0) {
                    asset.allocatedSubAssets = initializeAllocationType(asset.allocatedSubAssets); // Recursive for sub-assets
                }
                return asset;
            });
        };

        setAllocation((prevAllocation) => initializeAllocationType(prevAllocation));
    }, [UpdatedAllocation]); // Run only when `currentAllocationDetails` changes

    const handleAllocationChange = useCallback((assetId, field, value, details) => {
        setAllocation((prevAllocation) => {
            const updateAllocation = (assets) => {
                return assets.map((asset) => {
                    // change the specific value in specific field
                    if (asset._id === assetId) {
                        // Update the specific field
                        if (field === 'TLD') {
                            const total = asset.SAA + asset.TAA;
                            asset[field] = value
                            asset['TALT'] = total + value

                        }
                        else if (field === 'TUD') {
                            const total = asset.SAA + asset.TAA;
                            asset[field] = value
                            asset['TAUT'] = total + value
                        }
                        else if (field === 'SLD') {
                            asset[field] = value;
                            asset['SAALT'] = asset?.SAA + value;
                        }
                        else if (field === 'SUD') {
                            asset[field] =  value
                            asset['SAAUT'] = asset?.SAA + value
                        }
                        else {
                            asset[field] = value;
                        }
                    }

                    // If this allocation has sub-assets, recursively update them
                    if (asset.allocatedSubAssets && asset.allocatedSubAssets.length > 0) {
                        asset.allocatedSubAssets = updateAllocation(asset.allocatedSubAssets);

                        // Recalculate totals for this parent from its children
                        const totalSAA = asset.allocatedSubAssets.reduce(
                            (sum, child) => sum + (child.SAA || 0),
                            0
                        );
                        const totalTAA = asset.allocatedSubAssets.reduce(
                            (sum, child) => sum + (child.TAA || 0),
                            0
                        );


                        // Update the parent's SAA and TAA if it's not locked

                        // const filterAsset = details?.parentAssetAllocation && assets.find((asset) => asset._id === details?.parentAssetAllocation);
                        // if (filterAsset && filterAsset?.status !== 'lock') {
                        //     asset.SAA = totalSAA;
                        //     asset.TAA = totalTAA;
                        // }
                    }

                    return asset;
                });
            };

            // Update the specific allocation
            const updatedAllocations = updateAllocation(prevAllocation);

            // Propagate changes upward dynamically
            // return propagateUp(updatedAllocations, assetId, { totalSAA: value, totalTAA: 0 });
            return updatedAllocations;
        });
    }, []);


    const validateFullAllocation = (allocation) => {
        const errors = {};
        let isValid = true;

        const validateAllocation = (assets, path = "", isChild = false) => {
            return assets.map(asset => {
                const total = asset.SAA + asset.TAA;
                let hasChildError = false;

                const findClass = asset?.class?.value ? allClasses.filter((cls) => cls.name === asset?.class?.label) : allClasses.filter((cls) => cls.name === asset?.class);
                const classOption = findClass.length > 0 ? findClass[0] : {};

                if (asset?.name === "" || asset?.name === null || asset?.name === undefined) {
                    errors[`${asset?._id}_name`] = {
                        id: asset?._id,
                        message: "Name is required",
                        path: `${path}.${asset?._id}`,
                        isChild
                    }
                    isValid = false;
                }

                if (asset?.SAA < (Number(asset?.SAALT)) || asset?.SAA > (Number(asset?.SAAUT))) {
                    errors[`${asset?._id}_SAA`] = {
                        id: asset?._id,
                        message: "SAA is not within the range",
                        path: `${path}.${asset?._id}`,
                        isChild
                    }
                    isValid = false;
                }

                if (Number(asset?.TAUT) > 100) {
                    errors[`${asset?._id}_Total_Range`] = {
                        id: asset?._id,
                        message: "Total Tolerance Upper Should be less than or equal to 100",
                        path: `${path}.${asset?._id}`,
                        isChild
                    }
                    isValid = false;
                }

                if ((Number(asset?.TALT)) < 0) {
                    errors[`${asset?._id}_Total_Range`] = {
                        id: asset?._id,
                        message: "Total Tolerance Lower Should be grater than or equal to 0",
                        path: `${path}.${asset?._id}`,
                        isChild
                    }
                    isValid = false;
                }

                if ((Number(asset?.SAAUT)) > 100) {
                    errors[`${asset?._id}_SAA_RANGE`] = {
                        id: asset?._id,
                        message: "Strategic Tolerance Upper Should be less than or equal to 100",
                        path: `${path}.${asset?._id}`,
                        isChild
                    }
                    isValid = false;
                }
                if ((Number(asset?.SAALT)) < 0) {
                    errors[`${asset?._id}_SAA_RANGE`] = {
                        id: asset?._id,
                        message: "Strategic Tolerance lower Should be grater than or equal to 0",
                        path: `${path}.${asset?._id}`,
                        isChild
                    }
                    isValid = false;
                }

                if (total < (Number(asset?.TALT)) || total > Number(asset?.TAUT)) {
                    errors[`${asset?._id}_TA`] = {
                        id: asset?._id,
                        message: "Total is not within the range",
                        path: `${path}.${asset?._id}`,
                        isChild
                    }
                    isValid = false;
                }

                if (asset?.portfolio === null || asset?.portfolio === undefined) {
                    errors[`${asset?._id}_portfolio`] = {
                        id: asset?._id,
                        message: "Portfolio is required",
                        path: `${path}.${asset?._id}`,
                        isChild
                    }
                    isValid = false;
                }
                if ((asset?.authorizedAsset === null || asset?.authorizedAsset === undefined) &&
                    (asset?.asset === null || asset?.asset === undefined)) {
                    errors[`${asset?._id}_authorizedAsset`] = {
                        id: asset?._id,
                        message: "Asset is required",
                        path: `${path}.${asset?._id}`,
                        isChild
                    };
                    isValid = false;
                }

                if (asset?.class === null || asset?.class === undefined || Object.keys(classOption)?.length === 0) {
                    debugger
                    errors[`${asset?._id}_class`] = {
                        id: asset?._id,
                        message: "Class is required",
                        path: `${path}.${asset?._id}`,
                        isChild
                    }
                    isValid = false;
                }

                // Validate allocatedSubAssets (children)
                if (asset?.allocatedSubAssets && asset?.allocatedSubAssets.length > 0) {
                    asset.allocatedSubAssets = validateAllocation(asset.allocatedSubAssets, `${path}.${asset?._id}`, true);

                    // Check if any child allocations have errors
                    const childIds = asset.allocatedSubAssets.map(child => child._id);
                    if (childIds.some(childId => Object.keys(errors).some(errorKey => errorKey.startsWith(`${childId}_`)))) {
                        hasChildError = true;
                    }
                }

                // If there is a child error, propagate it to the parent
                if (hasChildError) {
                    errors[`${asset?._id}_childError`] = {
                        id: asset?._id,
                        message: "There are errors in child allocations",
                        path: `${path}.${asset?._id}`,
                        isChild
                    };
                    isValid = false;
                }

                return asset;
            })
        }

        validateAllocation(allocation);
        Object?.keys(errors)?.length > 0 ? setAllocationValidationErrors(errors) : setAllocationValidationErrors({});
        return isValid;
    }

    const toggleGroupExpansion = useCallback((group) => {
        setExpandedGroups(prev => ({ ...prev, [group]: !prev[group] }))
    }, [])


    const handleDeleteAllocation = useCallback((allocation) => {
        const preparePayload = {
            allocationId: JSON.parse(localStorage.getItem('currentAllocation'))?.value,
            assetAllocationId: allocation?._id
        }
        dispatch(removeAssetAllocation(preparePayload)).then((response) => {
            if (response?.data?.success) {
                dispatch(fetchAllocationsDetails(preparePayload?.allocationId)).then(res => {
                    if (res?.data?.success) {
                        // localStorage.setItem('currentAllocationDetails', JSON.stringify(res?.data?.data));
                        setCurrentAllocationDetails(res?.data?.data);
                        setAllocationValidationErrors({});
                    }
                    // if()
                });
            }
        })

    }, [])

    const calculateTotal = useCallback((path) => {
        let target = allocation
        const keys = path && path?.split('.')
        for (let i = 0; i < keys.length; i++) {
            if (!target[keys[i]]) {
                return 0 // If the path is invalid, return 0
            }
            target = target[keys[i]]
        }
        return (target.strategic || 0) + (target.tactical || 0)
    }, [allocation])

    const isWithinTolerance = useCallback((target, lowerTolerance, upperTolerance) => {
        let isValid = true;
        if (target < (Number(target) - Number(lowerTolerance)) || target > (Number(target) + Number(upperTolerance))) {
            isValid = false;
        }
        return isValid;
    }, []);

    const renderAllocationItem = useCallback((item, itemName, path = null, isSubAllocation = false, allPortfolio, allAuthorizedAssets, loading, setLoading) => {

        const hasNestedAllocations = item.allocatedSubAssets?.length > 0 ? true : false
        const fullPath = path ? `${path}.${itemName}` : `${itemName}`
        // const total = calculateTotal(fullPath)
        const isStrategicWithinTolerance = isWithinTolerance(item.SAA, item.SAALT, item.SAAUT)
        const isTotalWithinTolerance = isWithinTolerance((Number(item.SAA) + Number(item.TAA)), item?.TALT, item?.TAUT);
        // const totalSubSAA = item?.allocatedSubAssets?.reduce((sum, subItem) => sum + (subItem.SAA || 0), 0);
        // const totalSubTAA = item?.allocatedSubAssets?.reduce((sum, subItem) => sum + (subItem.TAA || 0), 0);

        // const isSubSAAMatching = hasNestedAllocations ? totalSubSAA === item.SAA : true;
        // const isSubTAAMatching = hasNestedAllocations ? totalSubTAA === item.TAA : true;
        const totalAllocation = Number(item.SAA) + Number(item.TAA);
        console.log(item)

        // const isExpanded = expandedAssets[asset._id]
        const isLocked = item?.status === 'lock' ? true : false;
        const isEditable = item.isEditable;
        const portfolios = allPortfolio || [];
        const authorizedAssets = allAuthorizedAssets || [];

        const subLoading = loading
        const setSubLoading = setLoading

        const handleLockUnlockAllocation = (item) => {
            const payload = {
                allocationId: JSON.parse(localStorage.getItem('currentAllocation'))?.value,
                assetAllocationId: item?._id
            }
            dispatch(lockUnlockAssetAllocation(payload)).then((response) => {
                if (response?.data?.success) {

                    setAllocation((prevAllocation) => {
                        const toggleLock = (assets) => {
                            return assets.map((asset) => {
                                if (asset._id === response?.data?.data?._id) {
                                    asset.status = asset.status === 'lock' ? 'unlock' : 'lock'
                                }
                                if (asset.allocatedSubAssets && asset.allocatedSubAssets.length > 0) {
                                    asset.allocatedSubAssets = toggleLock(asset.allocatedSubAssets);
                                }
                                return asset;
                            });
                        };
                        return toggleLock(prevAllocation);
                    });

                }
            })

        }

        const handleEditAllocation = (allocation) => {
            setAllocation((prev) => {
                const toggleEditable = (assets) => {
                    return assets.map((asset) => {
                        if (asset._id === allocation?._id) {
                            return { ...asset, isEditable: true }; // Toggle edit mode
                        }
                        if (asset.allocatedSubAssets && asset.allocatedSubAssets.length > 0) {
                            return {
                                ...asset,
                                allocatedSubAssets: toggleEditable(asset.allocatedSubAssets), // Recursively toggle nested assets
                            };
                        }
                        return asset;
                    });
                };
                return toggleEditable(prev);
            });
        };

        const addAllocations = (allocation, editableAssetId, setLoading) => {
            setLoading((prev) => {
                return {
                    status: true,
                    _id: editableAssetId
                }
            });
            // handle add allocation api call here
            dispatch(AddAllocationV2(allocation)).then((response) => {
                if (response?.data?.success) {
                    const currentAllocationDetailId = JSON.parse(localStorage.getItem('currentAllocation'))?.value || null;
                    dispatch(fetchAllocationsDetails(currentAllocationDetailId)).then(res => {
                        if (res?.data?.success) {
                            localStorage.setItem('currentAllocationDetails', JSON.stringify(res?.data?.data));

                            setAllocation((prevAllocation) => {
                                const setNonEditable = (assets) => {
                                    return assets.map((asset) => {
                                        if (asset?._id === editableAssetId) {
                                            asset.isEditable = false
                                        }
                                        if (asset.allocatedSubAssets && asset.allocatedSubAssets.length > 0) {
                                            asset.allocatedSubAssets = setNonEditable(asset.allocatedSubAssets);
                                        }
                                        return asset;
                                    });
                                };
                                return setNonEditable(prevAllocation);
                            });

                            setAllocationValidationErrors({});
                        }
                        setLoading(false);
                    });
                }

            })
        }

        const handleEditAdjustAllocationSave = (allocation, setLoading) => {
            if (validateFullAllocation([allocation])) {
                const preParePayload = {
                    allocation: localStorage.getItem('currentAllocation') ? JSON.parse(localStorage.getItem('currentAllocation'))?.value : null,
                    allocatedAssets: [{
                        name: allocation?.name,
                        class: allocation?.class?.value ? allocation?.class?.label : allocation?.class,
                        subClass: allocation?.subClass?.value ? allocation?.subClass?.label : allocation?.subClass,
                        type: allocation?.allocationType?.value,
                        SAA: allocation?.SAA,
                        asset: allocation?.authorizedAsset?.value ? allocation?.authorizedAsset?.value : allocation?.asset,
                        strategy: allocation?.strategy?.value ? allocation?.strategy?.value : allocation?.strategy?._id,
                        SAALT: allocation?.SAALT,
                        SAAUT: allocation?.SAAUT,
                        TAA: allocation?.TAA,
                        TALT: allocation?.TALT,
                        TAUT: allocation?.TAUT,
                        portfolio: allocation?.portfolio?.value ? allocation?.portfolio?.value : allocation?.portfolio?._id,
                        _id: allocation?._id,

                    }],
                }
                addAllocations(preParePayload, allocation?._id, setLoading)
            }
        }

        const strategyOptions = JSON.parse(localStorage.getItem('allStrategies')) || [];

        const portfolioOptions = allPortfolio?.length > 0 ? allPortfolio.map((portfolio) => {
            return {
                value: portfolio._id,
                label: portfolio.name,
                rest: { ...portfolio },
            }
        }) : [];

        const getPortfolioValues = (item) => {
            if (item?.portfolio?._id) {
                return {
                    value: item?.portfolio?._id,
                    label: item?.portfolio?.name,
                    rest: { ...item?.portfolio },
                }
            }

            return item?.portfolio
        }

        const allocationTypeOptions = [{
            value: 'Fixed Weight',
            label: 'Fixed Weight',
        }];

        const assetsAuthorized = allAuthorizedAssets?.length > 0 ? allAuthorizedAssets.filter((asset) => {
            return asset?.portfolio === item?.portfolio?.value ? item?.portfolio?.value : item?.portfolio?._id
        }) : []
        const authorizedAssetOptions = assetsAuthorized?.length > 0 ? assetsAuthorized.map((asset) => {
            return {
                value: asset._id,
                label: asset.assetName,
                rest: { ...asset },
            }
        }) : [];

        const displayAuthorizedAsset = (item) => {
            if (item?.authorizedAsset?.value) {
                return item?.authorizedAsset; // Return directly if authorizedAsset already exists with a value
            }

            // Find the asset by matching the asset ID

            const matchingAsset = assetsAuthorized.find(asset => asset._id === item?.asset);
            if (matchingAsset) {

                return {
                    value: matchingAsset._id,
                    label: matchingAsset.assetName,
                    asset: { ...matchingAsset }
                };
            }

            return null; // Return null if no matching asset is found
        };

        const classOptions = allClasses?.length > 0 ? allClasses.map((classItem) => {
            return {
                value: classItem.id,
                label: classItem.name,
                rest: { ...classItem },
            }
        }) : [];

        const subClassOptions = allSubClasses?.length > 0 ? allSubClasses.map((subClass) => {
            return {
                value: subClass.id,
                label: subClass.name,
                rest: { ...subClass },
            }
        }) : []

        return (
            <div key={item?._id} className="mb-6 border-b pb-6">
                <div className="flex items-center justify-between mb-2 pt-2">
                    <div className='flex justify-start items-center gap-0'>
                        <div className={`${hasNestedAllocations ? "pe-2" : ""}`}>
                            {hasNestedAllocations && (
                                <TooltipProviders tooltipMessage="Expand" direction="top">
                                    <Button
                                        variant="gray"
                                        className="bg-transparent p-[6px] group hover:bg-gray-100 transition-all delay-200 ease-in-out border-0 hover:text-black rounded-[6px]"
                                        size="sm"
                                        onClick={() => toggleGroupExpansion(fullPath)}
                                    >
                                        <div
                                            className={cn(
                                                "transition-transform duration-500  cursor-pointer ",
                                                expandedGroups[fullPath] ? "rotate-[90deg]" : "rotate-0"
                                            )}

                                        >
                                            <ChevronRight className="h-5 w-5" />
                                        </div>
                                    </Button>
                                </TooltipProviders>
                            )}
                        </div>
                        <div className='w-full'>
                            <div className="w-full flex justify-start items-center ">
                                <Label htmlFor="" className=" min-w-[130px] pb-1">
                                    Allocation Name <span className='text-rose-500'>*</span>
                                </Label>
                                <Input value={item?.name || ""} readOnly={!isEditable} placeholder="Enter Allocation Name" onChange={(e) => handleAllocationChange(item?._id, "name", e.target.value, item)} className="w-full" />

                            </div>

                            {
                                Object.keys(allocationValidationErrors || {})?.filter((key) => key.includes(`${item?._id}_name`))?.length > 0 && allocationValidationErrors[`${item?._id}_name`] && allocationValidationErrors[`${item?._id}_name`]?.id && ((allocationValidationErrors[`${item?._id}_name`]?.id) === item?._id) && <div className="text-rose-600 text-[12px]">{allocationValidationErrors[`${item?._id}_name`]?.message}</div>
                            }
                            {
                                Object.keys(allocationValidationErrors || {})?.filter((key) => key.includes(`${item?._id}_childError`))?.length > 0 && allocationValidationErrors[`${item?._id}_childError`] && allocationValidationErrors[`${item?._id}_childError`]?.id && ((allocationValidationErrors[`${item?._id}_childError`]?.id) === item?._id) && <div className="text-rose-600 text-[12px]">{allocationValidationErrors[`${item?._id}_childError`]?.message}</div>
                            }
                        </div>
                    </div>
                    <div className="flex items-center space-x-2">
                        <TooltipProviders tooltipMessage="Edit Allocation" direction="top">
                            <Button
                                variant="gray"
                                className="bg-transparent p-[6px] group hover:bg-gray-100 transition-all delay-200 ease-in-out hover:text-black rounded-[6px]"
                                size="sm"
                                onClick={() => handleEditAllocation(item)}>
                                <div className={cn(
                                    "transition-transform duration-[1500ms] easy-in-out",
                                    ""
                                )}>

                                    <Edit className="h-5 w-5 " />
                                </div>
                            </Button>
                        </TooltipProviders>
                        <TooltipProviders tooltipMessage="Lock / Unlock Allocation" direction="top">
                            <Button
                                variant="gray"
                                className="bg-transparent p-[6px] group hover:bg-gray-100 transition-all delay-200 ease-in-out hover:text-black rounded-[6px]"
                                size="sm"
                                onClick={() => handleLockUnlockAllocation(item)}>
                                <div className={cn(
                                    "transition-transform duration-[1500ms] easy-in-out",
                                    ""
                                )}>

                                    {isLocked ? <Lock className="h-5 w-5 text-destructive" /> : <Unlock className="h-5 w-5 text-destructive" />}
                                </div>
                            </Button>
                        </TooltipProviders>
                        <TooltipProviders tooltipMessage="Delete" direction="top">
                            <Button
                                variant="gray"
                                className="bg-transparent p-[6px] group hover:bg-gray-100 transition-all delay-200 ease-in-out hover:text-black rounded-[6px]"
                                size="sm"
                                onClick={() => handleDeleteAllocation(item)}>
                                <Trash2 className="h-5 w-5 text-destructive" />
                            </Button>
                        </TooltipProviders>

                    </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-2">
                    <div className="flex items-center space-x-2">
                        <div>
                            {/* Total Tolerance Upper and Lower Case Handle */}
                            <Label htmlFor={`total-tolerance-${fullPath}`} className="text-sm">Total Tolerance</Label>
                            <div className="flex items-center mt-1">
                                <Input
                                    type="number"
                                    value={item?.TLD || 0}
                                    onChange={(e) => handleAllocationChange(item?._id, 'TLD', Number(e.target.value), item)}
                                    className="w-16 mr-1"
                                    readOnly={!isEditable}
                                />
                                <Input
                                    type="number"
                                    value={item?.TUD || 0}
                                    onChange={(e) => handleAllocationChange(item?._id, 'TUD', Number(e.target.value), item)}
                                    className="w-16"
                                    readOnly={!isEditable}
                                />
                            </div>
                        </div>
                        <div>
                            <Label className="text-sm">Total: {totalAllocation}%</Label>
                            <div className={`text-xs mt-1 ${(Number(item.TAUT <= 100) && Number(totalAllocation >= item?.TALT && totalAllocation <= item?.TAUT) && item?.TALT >= 0) ? 'text-green-600' : 'text-rose-600'}`}>
                                Range: {Number(item?.TALT)}% - {Number(item?.TAUT)}%
                            </div>
                        </div>

                    </div>
                </div>
                {
                    Object.keys(allocationValidationErrors || {})?.filter((key) => key.includes(`${item?._id}_TA`))?.length > 0 && allocationValidationErrors[`${item?._id}_TA`] && allocationValidationErrors[`${item?._id}_TA`]?.id && ((allocationValidationErrors[`${item?._id}_TA`]?.id) === item?._id) && <div className="text-rose-600 text-[12px]">{allocationValidationErrors[`${item?._id}_TA`]?.message}</div>
                }
                {
                    Object.keys(allocationValidationErrors || {})?.filter((key) => key.includes(`${item?._id}_Total_Range`))?.length > 0 && allocationValidationErrors[`${item?._id}_Total_Range`] && allocationValidationErrors[`${item?._id}_Total_Range`]?.id && ((allocationValidationErrors[`${item?._id}_Total_Range`]?.id) === item?._id) && <div className="text-rose-600 text-[12px]">{allocationValidationErrors[`${item?._id}_Total_Range`]?.message}</div>
                }




                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                    <div>
                        <Label htmlFor={`strategic-${fullPath}`} className="text-sm">Strategic: {item.SAA}%</Label>
                        <div className="flex items-center mt-1">
                            <Slider
                                id={`strategic-${fullPath}`}
                                min={0}
                                max={100}
                                step={1}
                                value={[item.SAA]}
                                onValueChange={(val) => handleAllocationChange(item._id, 'SAA', val[0], item)}
                                disabled={!isEditable}
                                className="flex-grow mr-2"
                            />
                            <Input
                                type="number"
                                value={item.SAA || 0}
                                onChange={(e) => handleAllocationChange(item._id, 'SAA', Number(e.target.value), item)}
                                readOnly={!isEditable}
                                className="w-16"
                            />
                        </div>
                        <div className="flex items-center mt-1">
                            <Label className="text-xs mr-2">Range:</Label>
                            <Input
                                type="number"
                                value={item?.SLD || 0}
                                onChange={(e) => handleAllocationChange(item._id, 'SLD', Number(e.target.value), item)}
                                readOnly={!isEditable}
                                className="w-16 mr-1"
                            />
                            <Input
                                type="number"
                                value={item?.SUD || 0}
                                onChange={(e) => handleAllocationChange(item._id, 'SUD', Number(e.target.value), item)}
                                readOnly={!isEditable}
                                className="w-16"
                            />
                        </div>
                        <div className={`text-xs mt-1 ${(Number(item.SAAUT <= 100) && Number(item?.SAA >= item?.SAALT && item?.SAA <= item?.SAAUT) && item?.SAALT >= 0) ? 'text-green-600' : 'text-rose-600'}`}>
                            Range: {Number(item.SAALT)}% - {Number(item.SAAUT)}%
                        </div>
                        {
                            Object.keys(allocationValidationErrors || {})?.filter((key) => key.includes(`${item?._id}_SAA`))?.length > 0 && allocationValidationErrors[`${item?._id}_SAA`] && allocationValidationErrors[`${item?._id}_SAA`]?.id && ((allocationValidationErrors[`${item?._id}_SAA`]?.id) === item?._id) && <div className="text-rose-600 text-[12px]">{allocationValidationErrors[`${item?._id}_SAA`]?.message}</div>
                        }
                        {
                            Object.keys(allocationValidationErrors || {})?.filter((key) => key.includes(`${item?._id}_SAA_RANGE`))?.length > 0 && allocationValidationErrors[`${item?._id}_SAA_RANGE`] && allocationValidationErrors[`${item?._id}_SAA_RANGE`]?.id && ((allocationValidationErrors[`${item?._id}_SAA_RANGE`]?.id) === item?._id) && <div className="text-rose-600 text-[12px]">{allocationValidationErrors[`${item?._id}_SAA_RANGE`]?.message}</div>
                        }
                    </div>

                    <div>
                        <Label htmlFor={`tactical-${fullPath}`} className="text-sm">Tactical: {item.TAA}%</Label>
                        <div className="flex items-center mt-1">
                            <Slider
                                id={`tactical-${fullPath}`}
                                min={0}
                                max={100}
                                step={1}
                                value={[item.TAA]}
                                onValueChange={(val) => handleAllocationChange(item._id, 'TAA', val[0], item)}
                                disabled={!isEditable}
                                className="flex-grow mr-2"
                            />
                            <Input
                                type="number"
                                value={item.TAA || 0}
                                onChange={(e) => handleAllocationChange(item._id, 'TAA', Number(e.target.value), item)}
                                readOnly={!isEditable}
                                className="w-16"
                            />
                        </div>
                        {
                            Object.keys(allocationValidationErrors || {})?.filter((key) => key.includes(`${item?._id}_TAA`))?.length > 0 && allocationValidationErrors[`${item?._id}_TAA`] && allocationValidationErrors[`${item?._id}_TAA`]?.id && ((allocationValidationErrors[`${item?._id}_TAA`]?.id) === item?._id) && <div className="text-rose-600 text-[12px]">{allocationValidationErrors[`${item?._id}_TAA`]?.message}</div>
                        }

                    </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-4 gap-4"> {/* UPDATED GRID */}
                    <div>
                        <Label htmlFor="new-allocation-authorized-asset">Portfolio <span className='text-rose-500'>*</span></Label>
                        <CustomCreatableSelect
                            options={portfolioOptions}
                            classNamePrefix={'react_select'}
                            className='text-[12px] w-full'
                            onChange={(value) => handleAllocationChange(item._id, 'portfolio', value, item)}
                            value={getPortfolioValues(item)}
                            isDisabled={!isEditable}
                            placeholder="Select Portfolio"

                        />

                        {
                            Object.keys(allocationValidationErrors || {})?.filter((key) => key.includes(`${item?._id}_portfolio`))?.length > 0 && allocationValidationErrors[`${item?._id}_portfolio`] && allocationValidationErrors[`${item?._id}_portfolio`]?.id && ((allocationValidationErrors[`${item?._id}_portfolio`]?.id) === item?._id) && <div className="text-rose-600 text-[12px]">{allocationValidationErrors[`${item?._id}_portfolio`]?.message}</div>
                        }
                    </div>


                    <div>
                        <Label htmlFor="class">Class <span className='text-rose-500'>*</span></Label>
                        <CustomCreatableSelect
                            options={classOptions}
                            classNamePrefix={'react_select'}
                            className='text-[12px] w-full'
                            isDisabled={!isEditable}
                            onChange={(value) => handleAllocationChange(item?._id, 'class', { value: value?.value, label: value?.label }, item)}
                            value={item?.class?.value ? item?.class : classOptions?.find(classItem => classItem?.label === item?.class)}
                            placeholder="Select Class"
                        />
                        {
                            Object.keys(allocationValidationErrors || {})?.filter((key) => key.includes(`${item?._id}_class`))?.length > 0 && allocationValidationErrors[`${item?._id}_class`] && allocationValidationErrors[`${item?._id}_class`]?.id && ((allocationValidationErrors[`${item?._id}_class`]?.id) === item?._id) && <div className="text-rose-600 text-[12px]">{allocationValidationErrors[`${item?._id}_class`]?.message}</div>
                        }
                    </div>
                    <div>
                        <Label htmlFor="new-allocation-sub-class">Sub Class</Label>
                        <CustomCreatableSelect
                            options={subClassOptions}
                            classNamePrefix={'react_select'}
                            className='text-[12px] w-full'
                            isDisabled={!isEditable}
                            onChange={(value) => handleAllocationChange(item?._id, 'subClass', { value: value?.value, label: value?.label }, item)}
                            value={item?.subClass?.value ? item?.subClass : subClassOptions?.find(subClassItem => subClassItem?.label === item?.subClass)}
                            placeholder="Select Sub Class"
                        />
                    </div>

                    <div>
                        <Label htmlFor={`strategy-${fullPath}`} className="text-sm">Strategy</Label>
                        <CustomCreatableSelect
                            className="w-full"
                            placeholder="Select Strategy"
                            options={strategyOptions}
                            value={item?.strategy?.value ? item?.strategy : strategyOptions?.find(strategy => strategy?.value === item?.strategy)}
                            classNamePrefix="react_select"
                            isDisabled={!isEditable}
                            onChange={(value) => handleAllocationChange(item?._id, 'strategy', { value: value?.value, label: value?.label, strategy: { ...value?.strategy } }, item)}
                        />
                    </div>
                    <div>
                        <Label htmlFor="new-allocation-type">Allocation Type <span className='text-rose-500'>*</span></Label>
                        <CustomCreatableSelect
                            options={allocationTypeOptions}
                            classNamePrefix={'react_select'}
                            className='text-[12px] w-full'
                            isDisabled={!isEditable}
                            value={item?.allocationType}
                            placeholder="Select Allocation Type"
                        />
                    </div>
                    <div>
                        <Label htmlFor={`authorized-asset-${fullPath}`} className="text-sm">Authorised Asset</Label>
                        <CustomCreatableSelect
                            className="w-full"
                            placeholder="Enter Authorized Asset"
                            options={authorizedAssetOptions}
                            classNamePrefix="react_select"
                            value={displayAuthorizedAsset(item)}
                            isDisabled={!isEditable}
                            onChange={(value) => handleAllocationChange(item?._id, 'authorizedAsset', value, item)}
                        />

                        {
                            Object.keys(allocationValidationErrors || {})?.filter((key) => key.includes(`${item?._id}_authorizedAsset`))?.length > 0 && allocationValidationErrors[`${item?._id}_authorizedAsset`] && allocationValidationErrors[`${item?._id}_authorizedAsset`]?.id && ((allocationValidationErrors[`${item?._id}_authorizedAsset`]?.id) === item?._id) && <div className="text-rose-600 text-[12px]">{allocationValidationErrors[`${item?._id}_authorizedAsset`]?.message}</div>
                        }

                    </div>
                </div>
                <div>
                    {item?.isEditable && (
                        <div className="flex items-center justify-center mt-2">
                            <Button className="text-sm min-w-[90px]" disabled={loading?.status && loading?._id === item?._id} onClick={() => handleEditAdjustAllocationSave(item, setLoading)}>  {loading?.status && loading?._id === item?._id && <IconLoading height={13} width={13} />} Save</Button>
                        </div>
                    )}
                </div>
                {hasNestedAllocations && expandedGroups[fullPath] && (
                    <div className="mt-4 pl-4 border-l-2 border-muted-foreground">
                        {item?.allocatedSubAssets?.length > 0 ? item?.allocatedSubAssets?.map((subAllocation, index) =>
                            renderAllocationItem(subAllocation, subAllocation?._id, fullPath, true, portfolios, authorizedAssets, subLoading, setSubLoading)
                        ) : []}
                    </div>
                )}
            </div>
        )
    }, [calculateTotal, isWithinTolerance, handleAllocationChange, toggleGroupExpansion, handleDeleteAllocation, expandedGroups, allocationValidationErrors, allocation])

    const flattenAllocations = useCallback((allocations, prefix = '') => {
        return Object.entries(allocations).flatMap(([key, value]) => {
            const newPrefix = prefix ? `${prefix}.${key}` : key
            const result = [{ name: newPrefix, value }]
            if (value && value.subAllocations) {
                result.push(...flattenAllocations(value.subAllocations, newPrefix))
            }
            return result
        })
    }, [])

    const allAllocations = useMemo(() => flattenAllocations(allocation), [allocation, flattenAllocations])

    const calculateTotalStrategic = useMemo(() => {
        let total = 0;

        allocation.forEach(item => total += item.SAA)
        return total;
    }, [allocation])

    const calculateTotalTactical = useMemo(() => {
        let total = 0;
        allocation.forEach(item => total += item.TAA)
        return total;
    }, [allocation])

    const addAuditEntry = useCallback((action, details) => {
        setAuditLog(prevLog => [
            {
                timestamp: new Date().toLocaleString(),
                action,
                details
            },
            ...prevLog
        ]);
    }, []);

    const saveAllocation = useCallback(() => {
        if (currentAllocationName) {
            setSavedAllocations(prev => ({ ...prev, [currentAllocationName]: allocation }))
            setCurrentAllocationName('')
            addAuditEntry('Save Allocation', `Saved allocation: ${currentAllocationName}`);
        }
    }, [allocation, currentAllocationName])

    const loadAllocation = useCallback((name) => {
        if (savedAllocations[name]) {
            setAllocation(savedAllocations[name])
            addAuditEntry('Load Allocation', `Loaded allocation: ${name}`);
        }
    }, [savedAllocations])

    const exportAllocation = useCallback(() => {
        const jsonData = JSON.stringify(allocation, null, 2)
        const blob = new Blob([jsonData], { type: 'application/json' })
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = 'allocation.json'
        a.click()
        URL.revokeObjectURL(url)
    }, [allocation])

    const importAllocation = useCallback((e) => {
        const file = e.target.files[0]
        const reader = new FileReader()
        reader.onload = (e) => {
            try {
                const rawData = JSON.parse(e.target.result)
                const allFlattenData = flattenAllocationsWithParentReverse(rawData);
                console.log("allFlattenData", allFlattenData)
                const mappingAllocationData = allFlattenData?.length > 0 ? allFlattenData.map((item) => {

                    const data = {
                        name: item?.name,
                        class: item?.class?.value ? item?.class?.label : item?.class,
                        subClass: item?.subClass?.value ? item?.subClass?.label : item?.subClass,
                        type: item?.allocationType?.value,
                        SAA: item?.SAA,
                        asset: item?.authorizedAsset?.value ? item?.authorizedAsset?.value : item?.asset,
                        strategy: item?.strategy?.value ? item?.strategy?.value : item?.strategy?._id,
                        SAALT: item?.SAALT,
                        SAAUT: item?.SAAUT,
                        TAA: item?.TAA,
                        TALT: item?.TALT,
                        TAUT: item?.TAUT,
                        portfolio: item?.portfolio?.value ? item?.portfolio?.value : item?.portfolio?._id,

                    }
                    if (item?.parentAssetAllocation) {
                        data.parentAssetAllocation = item?.parentAssetAllocation;
                    }

                    return data;
                }) : [];

                const prepareData = {
                    allocation: localStorage.getItem('currentAllocation') ? JSON.parse(localStorage.getItem('currentAllocation'))?.value : null,
                    allocatedAssets: mappingAllocationData
                }


                dispatch(AddAllocationV2(prepareData)).then((response) => {
                    if (response?.data?.success) {
                        const currentAllocationDetailId = JSON.parse(localStorage.getItem('currentAllocation'))?.value || null;
                        dispatch(fetchAllocationsDetails(currentAllocationDetailId)).then(res => {
                            if (res?.data?.success) {
                                localStorage.setItem('currentAllocationDetails', JSON.stringify(res?.data?.data));
                                setCurrentAllocationDetails(res?.data?.data);
                            }
                        });

                        // setAddAllocationLoading(false);
                        // setAddSubAllocationLoading(false);
                    }
                })
            } catch (error) {
                console.error('Error parsing JSON:', error)
            }
        }
        reader.readAsText(file)
    }, [allocation])

    useEffect(() => {
        // Reset selected portfolio when group changes
        setSelectedPortfolio("")
    }, [selectedPortfolioGroup]);

    const filterEditableRecordsNested = (records) => {
        const filterRecords = []
        const filterAssets = (assets) => {

            assets.forEach(asset => {
                if (asset.isEditable) {
                    filterRecords.push(asset);
                }
                if (asset.allocatedSubAssets && asset.allocatedSubAssets.length > 0) {
                    filterAssets(asset.allocatedSubAssets);
                }
            });

        }
        filterAssets(records);
        return filterRecords;
    };

    const handleSaveAllocation = (setAdjustSaveLoading) => {
        // Your save logic
        if (validateFullAllocation(allocation)) {
            setAdjustSaveLoading((prev) => {
                return { status: true, _id: 'all' }
            })

            const allocationSendingRecords = filterEditableRecordsNested(allocation);
            const preParePayload = allocationSendingRecords?.length > 0
                ? allocationSendingRecords.map((allocation) => {
                    const payload = {
                        name: allocation?.name,
                        class: allocation?.class?.value ? allocation?.class?.label : allocation?.class,
                        subClass: allocation?.subClass?.value ? allocation?.subClass?.label : allocation?.subClass,
                        type: allocation?.allocationType?.value,
                        SAA: allocation?.SAA,
                        asset: allocation?.authorizedAsset?.value ? allocation?.authorizedAsset?.value : allocation?.asset,
                        strategy: allocation?.strategy?.value ? allocation?.strategy?.value : allocation?.strategy?._id,
                        SAALT: allocation?.SAALT,
                        SAAUT: allocation?.SAAUT,
                        TAA: allocation?.TAA,
                        TALT: allocation?.TALT,
                        TAUT: allocation?.TAUT,
                        portfolio: allocation?.portfolio?.value ? allocation?.portfolio?.value : allocation?.portfolio?._id,
                        _id: allocation?._id,
                    };

                    // Only include parentAssetAllocation if it exists
                    if (allocation?.parentAssetAllocation) {
                        payload.parentAssetAllocation = allocation.parentAssetAllocation;
                    }

                    return payload;
                })
                : [];

            const currentAllocationDetailId = JSON.parse(localStorage.getItem('currentAllocation'))?.value || null;

            dispatch(AddAllocationV2({ allocation: currentAllocationDetailId, allocatedAssets: preParePayload })).then((response) => {
                if (response?.data?.success) {
                    const currentAllocationDetailId = JSON.parse(localStorage.getItem('currentAllocation'))?.value || null;
                    dispatch(fetchAllocationsDetails(currentAllocationDetailId)).then(res => {
                        if (res?.data?.success) {
                            localStorage.setItem('currentAllocationDetails', JSON.stringify(res?.data?.data));

                            setAllocation((prevAllocation) => {
                                const setNonEditable = (assets) => {
                                    return assets.map((asset) => {
                                        asset.isEditable = false; // Disable editing
                                        if (asset.allocatedSubAssets && asset.allocatedSubAssets.length > 0) {
                                            asset.allocatedSubAssets = setNonEditable(asset.allocatedSubAssets);
                                        }
                                        return asset;
                                    });
                                };
                                return setNonEditable(prevAllocation);
                            });
                        }
                        setAdjustSaveLoading(false)
                    });
                }
            })
        }
    };

    return (
        <div>
            <DashboardMaster>
                <div className='py-5 px-8'>
                    <Card className="bg-white " >
                        <CardHeader>
                            <CardTitle className="text-xl">{currentAllocation?.label}</CardTitle>
                            <CardDescription className="pt-1">{currentAllocation?.rest?.description || ""} </CardDescription>
                        </CardHeader>
                        <CardContent className="pb-3">
                            <div className='p-3'>
                                <Suspense fallback={<div className='h-[200px] flex justify-center items-center '><Loading /></div>}>
                                    <MainTabs
                                        allocation={allocation}
                                        auditLog={auditLog}
                                        renderAllocationItem={renderAllocationItem}
                                        exportAllocation={exportAllocation}
                                        importAllocation={importAllocation}
                                        setTargetType={setTargetType}
                                        targetType={targetType}
                                        allAuthorizedAssets={allAuthorizedAssets}
                                        handleValidateAndSaveAllocationData={handleSaveAllocation}
                                        mainTabs={mainTabs}
                                        setMainTabs={setMainTabs}
                                        allStrategies={allStrategies}
                                        editableAllocations={editableAllocations}
                                        allocationValidationErrors={allocationValidationErrors}
                                        setCurrentAllocationDetails={setCurrentAllocationDetails}
                                        validateFullAllocation={validateFullAllocation}
                                        setAllocationValidationErrors={setAllocationValidationErrors}
                                        allPortfolios={allPortfolios}
                                        allClasses={allClasses}
                                        allSubClasses={allSubClasses}
                                        adjustSaveLoading={adjustSaveLoading}
                                        setAdjustSaveLoading={setAdjustSaveLoading}
                                    />

                                </Suspense>
                            </div>
                        </CardContent>
                    </Card>
                    <div className='pt-4'>
                        <Suspense fallback={<div className='h-[200px] bg-white rounded-md flex justify-center items-center '><Loading /></div>}>
                            {!['settings', 'audit']?.includes(mainTabs) && <TotalAllocationCheck calculateTotalStrategic={calculateTotalStrategic} calculateTotalTactical={calculateTotalTactical} />}
                        </Suspense>
                    </div>
                </div>
            </DashboardMaster>
        </div>
    )
}

export default DynamicAllocation;

const flattenAllocationsWithParentReverse = (allocations) => {
    let result = [];

    const flattenAllocations = (allocations) => {

        allocations.forEach((allocation) => {
            const { allocatedSubAssets = [], _id, ...rest } = allocation;
            if (allocation?.parentAssetAllocation) {

                result.push({
                    ...rest,
                    _id,
                    parentAssetAllocation: allocation?.parentAssetAllocation,
                });
            } else {
                result.push({
                    ...rest,
                    _id,
                });
            }

            // Recursively process sub-assets
            if (allocatedSubAssets.length > 0) {
                flattenAllocations(allocatedSubAssets);
            }
        });

    }

    flattenAllocations(allocations);

    return result || [];
}